import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../../_shared/grid";
import { keyBy, sortBy } from "lodash";
import Loader from "../../../app-components/loader";
import { customComparator } from "../../_shared/helper";
import {
  AssociatedItemRenderer,
  Toggle,
  UsaceApprovedCell,
} from "../grid-renderers";
import { allowedIds } from "../../_shared/sysadmin-users";

class Platforms extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.updateDropdownItems = this.updateDropdownItems.bind(this);
  }

  updateDropdownItems(data) {
    const { platformsItems, aircraftConfigurationItems } = this.props;
    let platformsObject = keyBy(platformsItems, "id");
    let filtered = aircraftConfigurationItems.filter(
      (item) => item.gcs_id === data.id && item.approved
    );
    filtered = filtered.map((item) => {
      return { ...platformsObject[item.aircraft_id] };
    });
    filtered.sort((a, b) => {
      if (a.model > b.model) return 1;
      else return -1;
    });
    return { items: filtered, numSelected: filtered.length };
  }

  handleSelect(slug) {
    const { doUpdateRelativeUrl, orgsByRoute } = this.props;
    doUpdateRelativeUrl(`/${orgsByRoute.slug}/platforms/${slug}`);
  }

  render() {
    const {
      platformsGcsItems: items,
      platformsIsSaving,
      doPlatformsGcsPut,
      tokenKeyCloakId,
      platformsItems,
      orgsByRoute,
    } = this.props;

    const config = {
      suppressScrollOnNewData: true,
      rowHeight: 40,
      columnDefs: [
        {
          resizable: true,
          sortable: true,
          headerName: "Make",
          field: "make",
          filter: "agTextColumnFilter",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Model",
          field: "model",
          filter: "agTextColumnFilter",
          cellRenderer: (params) => {
            return (
              <a href={`/${orgsByRoute.slug}/platforms/${params.data.slug}`}>
                {params.data.model}
              </a>
            );
          },
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: false,
          headerName: "USACE Approved",
          field: "usace_approved",
          filter: "agTextColumnFilter",
          cellRenderer: allowedIds.includes(tokenKeyCloakId)
            ? Toggle
            : UsaceApprovedCell,
          valueGetter: (p) => p.data,
          cellRendererParams: {
            doSave: doPlatformsGcsPut,
            items,
          },
        },
        {
          resizable: true,
          sortable: false,
          headerName: "Approved Aircraft",
          field: "usace_approved",
          filter: "agTextColumnFilter",
          cellRenderer: AssociatedItemRenderer,
          valueGetter: (p) => p.data,
          cellRendererParams: {
            items: platformsItems,
            updateDropdownItems: this.updateDropdownItems,
            totalRows: items.length,
            displayOnly: true,
            type: "Aircraft",
          },
        },
      ],
    };

    let sorted = sortBy(items, ["make", "model"]);
    if (platformsIsSaving) {
      return (
        <div className="container-fluid mt-4">
          <Loader opt="dissolve-cube" />
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12">
          <div className="card platforms-grid-card">
            <div className="card-header">Approved Platforms</div>
            <div className="card-body p-0">
              <Grid
                config={config}
                data={sorted.filter((s) => s.usace_approved)}
                style={{
                  height: `${window.innerHeight * 0.8}px`,
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="card platforms-grid-card">
            <div className="card-header">Unapproved Platforms</div>
            <div className="card-body p-0">
              <Grid
                config={config}
                data={sorted.filter((s) => !s.usace_approved)}
                style={{
                  height: `${window.innerHeight * 0.8}px`,
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "selectTokenUsername",
  "selectPlatformsGcsItems",
  "selectPlatformsItems",
  "selectPlatformsByRoute",
  "selectOrgsByRoute",
  "selectPlatformsGcsIsLoading",
  "doPlatformsGcsPut",
  "selectTokenPayload",
  "doDialogOpen",
  "doPlatformsGcsPut",
  "selectAircraftConfigurationItems",
  "selectTokenKeyCloakId",
  Platforms
);
