import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import { find, startsWith } from "lodash";
import classnames from "classnames";
import { format } from "date-fns";
import HealthAssessment from "../modals/health-assessment";
import Loader from "../../../../app-components/loader";
const HealthAssessmentCard = ({
  routeInfo,
  healthAssessmentItems,
  healthAssessmentApprovalItems,
  healthAssessmentIsLoading: isFormLoading,
  healthAssessmentIsSaving: isFormSaving,
  healthAssessmentApprovalIsLoading: isApprovalsLoading,
  healthAssessmentApprovalIsSaving: isApprovalsSaving,
  doHealthAssessmentSelectForm,
  healthAssessmentSelectedForm,
  efTwentyActive,
  doNestedDialogOpen,
  profileAlertItems,
}) => {
  const [state, setState] = useState({
    currentForm: { atp_year: null },
    forms: healthAssessmentItems,
    alert: find(profileAlertItems, (o) =>
      startsWith(o.alert_type, "Health Assessment")
    ),
    formStatus: "warning",
    atp_month: null,
    atp_to: null,
    atp_from: null,
  });
  const onClick = () => {
    doHealthAssessmentSelectForm(
      {
        ...state.currentForm,
        name: efTwentyActive.name,
      },
      () => {
        doNestedDialogOpen({
          content: HealthAssessment,
          props: {
            scrollable: true,
            atp_month: efTwentyActive.atp_month,
          },
          size: "xl",
        });
      }
    );
  };
  const onNextAtpClicked = () => {
    doHealthAssessmentSelectForm({
      meets_all_requirements: false,
      waiver_needed: false,
      permanent_condition: false,
      acknowledged: false,
      foa: "",
      waiver_submitted_date: null,
      waiver_approved_date: null,
      atp_year: efTwentyActive.atp_from,
      profile_id: efTwentyActive.profile_id,
      name: efTwentyActive.name,
      approvals: [],
    });
    doNestedDialogOpen({
      content: HealthAssessment,
      props: { scrollable: true },
      size: "xl",
    });
  };

  // Create entries in alerts table for Health Assessment (below)

  const updateHealthAssessmentList = (callback) => {
    if (Object.keys(efTwentyActive).length !== 0) {
      let { atp_to, atp_from } = efTwentyActive;
      let missing = find(profileAlertItems, {
        alert_type: "Health Assessment Missing",
      });
      let expiring = find(profileAlertItems, {
        alert_type: "Health Assessment Expiring",
      });
      let atp_year = !missing && expiring ? atp_to : atp_from;

      let stateUpdate = {
        ...state,
        currentForm: {
          ...state.currentForm,
          atp_year: atp_year ? atp_year : new Date().getUTCFullYear(),
        },
      };
      if (healthAssessmentItems.length > 0) {
        let currentItem = healthAssessmentSelectedForm;
        let firstItem = currentItem.id ? currentItem : healthAssessmentItems[0];
        let expiresOn = new Date(
          firstItem.atp_year,
          parseInt(efTwentyActive.atp_month) + 12,
          0
        );
        let expiration_date = format(expiresOn, "yyyy-MM-dd");
        stateUpdate = {
          ...stateUpdate,
          currentForm: {
            ...firstItem,
            approvals: healthAssessmentApprovalItems,
            expiration_date,
          },
          forms: healthAssessmentItems.filter((item) => !item.statusCode),
          formStatus:
            stateUpdate.alert &&
            stateUpdate.alert.type &&
            ["danger", "warning"].includes(stateUpdate.alert.type)
              ? stateUpdate.alert.type
              : "",
        };
        setState({ ...state, ...stateUpdate });
        callback && callback(firstItem, expiration_date);
      } else setState({ ...state, ...stateUpdate });
    }
  };

  useEffect(() => {
    updateHealthAssessmentList((item, date) =>
      doHealthAssessmentSelectForm({ ...item, date })
    );
  }, []);
  useEffect(() => {
    updateHealthAssessmentList();
  }, [healthAssessmentItems]);

  useEffect(() => {
    if (profileAlertItems) {
      let newAlert = find(profileAlertItems, (o) =>
        startsWith(o.alert_type, "Health Assessment")
      );
      setState({
        ...state,
        alert: newAlert,
        formStatus:
          newAlert &&
          newAlert.type &&
          ["danger", "warning"].includes(newAlert.type)
            ? newAlert.type
            : "",
      });
    }
  }, [profileAlertItems]);

  const renderCardContent = () => {
    const { atp_month, atp_to, atp_from } = efTwentyActive;
    let onProfilePage = routeInfo.url && routeInfo.url.includes("/profile");
    const newFormBtnCls = classnames({
      btn: true,
      "btn-link": state.alert,
      "btn-outline-secondary": !state.alert,
      "mt-2 p-0": true,
    });
    if (!atp_month) {
      return <h6 className="text-muted">EF-7120 must be completed</h6>;
    } else if (
      isFormLoading ||
      isFormSaving ||
      isApprovalsLoading ||
      isApprovalsSaving
    ) {
      return <Loader />;
    } else {
      return (
        <>
          <div className="d-flex flex-row justify-content-between">
            <span>
              <strong className="mr-1">ATP Year:</strong>
              <select
                value={state.currentForm.atp_year || ""}
                disabled={state.forms.length === 0}
                onChange={(e) => {
                  let selected = find(healthAssessmentItems, {
                    atp_year: parseInt(e.currentTarget.value),
                  });
                  setState({ ...state, currentForm: selected });
                  doHealthAssessmentSelectForm(selected);
                }}
              >
                {state.forms.map((item, idx) => {
                  return (
                    <option value={item.atp_year} key={idx}>
                      {item.atp_year}
                    </option>
                  );
                })}
              </select>
            </span>
            <button
              className="btn btn-link ml-2 p-0"
              disabled={state.forms.length === 0}
              onClick={onClick}
            >
              View Form
            </button>
          </div>
          <div className="row ml-2 no-gutters">
            <h6 className="col-9 text-muted mr-2 my-auto">User Signature:</h6>
            {state.currentForm &&
            state.currentForm.routing_order &&
            state.currentForm.routing_order >= 0 ? (
              <i className="col-auto mdi mdi-checkbox-marked-circle-outline text-success mdi-18px" />
            ) : (
              "N/A"
            )}
          </div>
          <div className="row ml-2 no-gutters">
            <h6 className="col-9 text-muted mr-2 my-auto">ATPM Signature:</h6>
            {state.currentForm && state.currentForm.routing_order === 1 ? (
              <i className="col-auto mdi mdi-checkbox-marked-circle-outline text-success mdi-18px" />
            ) : (
              "N/A"
            )}
          </div>
          {state.alert && onProfilePage ? (
            <button className={newFormBtnCls} onClick={onNextAtpClicked}>
              <p className="card-text">
                <span className="mdi mdi-plus-circle mr-1" />
                Create New ({atp_from})
              </p>
            </button>
          ) : state.alert && !onProfilePage ? (
            <p className="card-text text-muted">
              To create new form, user must access their Flight Training Folder
              from their <strong>Profile Page</strong>.
            </p>
          ) : (
            <p className="card-text text-muted">
              <strong>
                Next Form Due:{" "}
                {`${
                  parseInt(atp_month) + 1 > 12
                    ? parseInt(atp_month) + 1 - 12
                    : parseInt(atp_month) + 1
                }/1/${atp_to}`}
              </strong>
            </p>
          )}
        </>
      );
    }
  };
  let cardCls = classnames({
    "card border position-relative": true,
    [`border-${state.formStatus}`]: true,
  });
  return (
    <div className={cardCls} style={{ width: "18rem" }}>
      {state.formStatus === "warning" ||
        (state.formStatus === "danger" && (
          <i
            className={`position-absolute mdi mdi-alert-outline mdi-18px text-${state.formStatus}`}
            style={{ top: 16, right: 12 }}
          />
        ))}
      <div className="card-body p-3">
        <h5 className="card-title border-bottom pb-2">Health Assessment</h5>
        {renderCardContent()}
      </div>
    </div>
  );
};

export default connect(
  "selectRouteInfo",
  "selectProfileAlertItems",
  "selectEfTwentyActive",
  "doNestedDialogOpen",
  "doHealthAssessmentSelectForm",
  "selectHealthAssessmentApprovalItems",
  "selectHealthAssessmentIsLoading",
  "selectHealthAssessmentIsSaving",
  "selectHealthAssessmentApprovalIsLoading",
  "selectHealthAssessmentApprovalIsSaving",
  "selectHealthAssessmentItems",
  "selectHealthAssessmentSelectedForm",
  HealthAssessmentCard
);
