import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";
import { format } from "date-fns";
const defaultFormState = {
  id: "",
  profile_id: "",
  meets_all_requirements: false,
  waiver_needed: false,
  permanent_condition: false,
  acknowledged: false,
  foa: "",
  waiver_submitted_date: null,
  waiver_approved_date: null,
  preparer_sig: null,
  preparer_sig_name: "",
  atp_year: null,
  atpm_sig: null,
  disabled: false,
  approvals: [],
};
export default createRestBundle({
  name: "healthAssessment",
  uid: "id",
  prefetch: true,
  routeParam: "formId",
  getTemplate: "/profile/:id/healthAssessment?:queryString",
  putTemplate: "/profile/:id/healthAssessment/:item.id",
  postTemplate: "/profile/:id/healthAssessment",
  urlParamSelectors: ["selectHealthAssessmentFormattedQueryString"],
  forceFetchActions: [
    "EFTWENTY_FETCH_FINISHED",
    "HEALTHASSESSMENT_SAVE_FINISHED",
    "HEALTHASSESSMENT_DELETE_FINISHED",
    "HEALTHASSESSMENTAPPROVAL_SAVE_FINISHED",
    "HEALTHASSESSMENTAPPROVAL_DELETE_FINISHED",
  ],
  initialData: {
    _selectedForm: defaultFormState,
    _forms: [],
  },
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "EFTWENTY_FETCH_STARTED":
        return Object.assign({}, state, {
          ...payload,
          _selectedForm: defaultFormState,
        });
      case "HEALTHASSESSMENT_FORM_SELECTED":
      case "HEALTHASSESSMENT_UPDATE_SELECTED_FORM":
      case "HEALTHASSESSMENT_UPDATE":
        return Object.assign({}, state, payload);
      default:
        return state;
    }
  },
  addons: {
    selectHealthAssessmentQueryString: (state) => {
      return state.healthAssessment._queryString;
    },
    selectHealthAssessmentFormattedQueryString: createSelector(
      "selectRouteInfo",
      "selectProfileActiveData",
      "selectEfTwentyActive",
      (routeInfo, profileActiveData, efTwentyActive) => {
        let queryItems = [];
        if (routeInfo.pattern.includes("/profile")) {
          queryItems.push(
            `profileId=${encodeURIComponent(profileActiveData.id)}`
          );
          return { ...profileActiveData, queryString: queryItems.join("&") };
        } else if (routeInfo.pattern.includes("/:orgSlug/atpm")) {
          queryItems.push(
            `profileId=${encodeURIComponent(efTwentyActive.profile_id)}`
          );
          return { ...profileActiveData, queryString: queryItems.join("&") };
        }
        return {};
      }
    ),
    doHealthAssessmentUpdateSelectedForm:
      (update) =>
      ({ dispatch, store }) => {
        let selectedForm = store.selectHealthAssessmentSelectedForm();
        dispatch({
          type: "HEALTHASSESSMENT_UPDATE_SELECTED_FORM",
          payload: {
            _selectedForm: { ...selectedForm, ...update },
          },
        });
      },
    doHealthAssessmentSelectForm:
      (update, callback) =>
      ({ dispatch, store }) => {
        const efTwentyActive = store.selectEfTwentyActive();
        const { atp_year } = update;
        let expiresOn = new Date(
          atp_year,
          parseInt(efTwentyActive.atp_month) + 12,
          0
        );
        let expiration_date = null;
        if (atp_year) {
          expiration_date = format(expiresOn, "yyyy-MM-dd");
        }

        dispatch({
          type: "HEALTHASSESSMENT_FORM_SELECTED",
          payload: {
            _selectedForm: { ...update, expiration_date: expiration_date },
          },
        });
        callback && callback();
      },
    selectHealthAssessmentSelectedForm: (state) => {
      return state.healthAssessment._selectedForm;
    },
  },
});
